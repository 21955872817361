/*
Developed by Aptus Engineering, Inc. <https://aptusai.com>
See LICENSE.md file in project root directory
*/


.closedDropdown {
  height: 0px;
  overflow: hidden;
  visibility: none;
}

.injuryOptionsContainer {

  margin: -10px;
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: -5px;

}

/* Scroll Bar */
.injuryOptionsContainer::-webkit-scrollbar {
  width: 5px;
  height: 0px;
  
}
.injuryOptionsContainer::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 1);
  border-radius: 15px;
}
.injuryOptionsContainer::-webkit-scrollbar-thumb {
  background: rgba(150, 150, 150, 1);
  border-radius: 15px;
}
.injuryOptionsContainer::-webkit-scrollbar-thumb:hover {
  background: rgb(85, 85, 85);
}


.injuryTypeOption {

  position: relative; 
  margin-top: 5px;
  margin-left: 10px;
  width: calc(100% - 48px);
  overflow: auto; 
  padding: 0 10px;

  line-height: 30px;
  text-align: left;
  
  cursor: pointer;
  color: rgb(160,160,160);
  border: 1px solid rgb(80,80,80);
  /* border-radius: 4px; */
  transition: all 0.1s ease-in-out;
}

.injuryTypeOption_title {
    display: inline-block;
    position: relative;
}

.injuryTypeOption_edit {
    display: inline-block;
    position: absolute;
    height: 13px;
    width: 13px;
    top: 9px;
    right: 8px
}

.injuryTypeOption_edit:hover {
    transform: scale(1.3);
    transition: all 0.1s ease-in-out;
}

.injuryTypeOption:hover {

  background-color: rgba(100, 100, 100, 0.3);
  color: rgba(210,210,210, 1);
}

.btnConfirm {
    position: relative;
    float: right;
    top: 10px;
}