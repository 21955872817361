/*
Developed by Aptus Engineering, Inc. <https://aptusai.com>
See LICENSE.md file in project root directory
*/


/* #region Top */

.injuryTypeGenerator_background {
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    z-index: 80;
    width: 100%;
    height: 100%;
}

.injuryTypeGeneratorChooser_background {
    position: fixed;
    background-color: rgba(0,0,0,0.5);  
    z-index: 89;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
}

.injuryTypeGeneratorContainer {
    position: absolute;
    max-height: calc(100% - 34px);
    background-color: rgb(36, 36, 36);
    color: rgba(255,255,255,0.7);
    border: 1px solid rgb(50,50,50);
    border-radius: 4px;
    visibility: visible;
    text-align: center;
    z-index: 82;
}

.propertyPickerContainer {
    position: relative; 
    padding-top: 5px;
}

.propertyPickerHeader {
    position: relative;
    height: 25px;
    padding: 10px;
}

.injuryNameContainer {
    position: absolute;
    left: 20px;
    text-align: left;
}

/* Globality Indicator */

.injuryGlobalityContainer {
    position: absolute;
    right: 20px;
    z-index: 82;
    height: auto;
}

.injuryNameTitle {
    color: rgb(160,160,160) !important;
    height: 30px;
    vertical-align: middle;
}

.injuryNameTextbox {
    position: relative;
    width: 360px !important;
    height: 25px !important;
    font-size: 18px !important;
    top: -2px !important;
}

.globalityTitle {
    color: rgb(160,160,160) !important;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}

.globalitySwitch {
    display: inline-block;
    vertical-align: middle;
}

.injuryTypeGenerator_titleContainer {
    position: relative;
    height: 30px;
    width: 100%;
    border-bottom: 1px solid rgb(50,50,50);
}

.injuryTypeGenerator_title {
    color: rgb(160,160,160);
    text-align: left !important;
    font-size: 20px;
    margin-top: 16px;
    margin-left: 20px;
}

/* #endregion Top */

/* #region Variables container */

.variablesContainer{
    position: relative;
    max-height: 350px;
    height: auto;
    overflow: hidden; 
    overflow-y: auto;
}

/* Scroll Bar */
.variablesContainer::-webkit-scrollbar {
    width: 5px;
    height: 0px;
    
  }
 .variablesContainer::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 1);
    border-radius: 15px;
  }
 .variablesContainer::-webkit-scrollbar-thumb {
    background: rgba(150, 150, 150, 1);
    border-radius: 15px;
  }
 .variablesContainer::-webkit-scrollbar-thumb:hover {
    background: rgb(85, 85, 85);
  }

.newPropButtonContainer {
    cursor: pointer;
    /* display: inline; */
    position: relative;
    
    left: 55px;
    width: 190px;
    height: 30px;
    margin-bottom: 15px;
    /* border: 1px solid red; */

}

.newPropButtonContainer:hover {
    transform: scale(1.05);
    transition: ease-in-out 0.1s all;
}

.newPropButton {
    position: relative;
    display: inline-block;
    float: left;
    /* left: 0;
    top:0; */
    width: 30px !important;
}

/* .newPropButton:hover {
    width: 32px !important;
} */

.newPropButtonText {
    float: left;
    margin-left: 5px;
    margin-top: 5px;
    position: relative;
    display: inline-block;
    color: rgb(100,100,100);
}

/* #endregion Variables container */

/* #region footer */
.footerButtonContainer {
    height: 45px;
}

.btnClear {
    position: absolute;
    left: 15px;
    bottom: 10px;
    background-color: rgba(255, 135, 37, 0.8) !important;
}

.btnCreateInjury {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: rgba(48, 167, 44, 0.8) !important;
}

/* #endregion footer */

/* #region RWR text generator */

.txtAreaRwr:hover {
    background-color: rgb(50,50,50);
}

.txtAreaRwr {
    margin-bottom: 0 !important;
}

.txtArea {
    margin: 15px;
    border-radius: 3px;
    padding: 5px;
    border: 1px solid rgb(60,60,60);
    color: rgb(160,160,160) !important;
    background-color: rgba(0,0,0,0);
    font-size: 14px !important;
    line-height: 18px !important;
    top: 0%;
    width: 90%;
    max-width: 90%;
    min-width: 90%;
    min-height: 80px;
    height: auto;
}

/* #endregion RWR text generator */

/* #region Injury Type Generator Property */

.injuryTypeGeneratorProperty {
    position: relative;
    width: 100%;
    height: 30px;
    margin-bottom: 5px;
}

/* Property Key */

.keyContainer {
    position: relative; 
    display: inline-block;
    float: left;
    margin-left: 20px;
    height: 100%;
    width: auto;
}

.propertyKeyLabel {
    display: inline-block;
    margin-right: 10px;
}

.propertyKeyTextBox {
    display: inline-block;
}

/* Property Type */

.typeContainer {
    position: relative; 
    display: inline-block;

    float: left;
    margin-left: 20px;
    height: 100%;
    width: auto;
}

.propertyTypeLabel {
    display: inline-block;
    margin-right: 10px;
}

.propertyTypeSelect {
    display: inline-block;
}

/* Property Default */

.defaultContainer {
    position: relative;
    display: inline-block;
    float: left;
    margin-left: 20px;
}

.defaultLabel {
    display: inline-block;
    margin-right: 10px;
}

.defaultSelect {
    display: inline-block;
}

.defaultTextBox {
    display: inline-block;
}


/* #endregion Injury Type Generator Property */

/* #region Option chooser */

.injuryTypeGeneratorOptionChooser {
    position: absolute;
    height: auto;    
    background-color: rgb(45,45,45);
    border: 1px solid rgb(80,80,80);
    z-index: 90;
}

.optionsContainer {
    position: relative;
    max-height: 600px;
}

.optionsInputsContainer::-webkit-scrollbar {
    width: 5px;
}

.optionsInputsContainer::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 1);
    border-radius: 15px;
}

.optionsInputsContainer::-webkit-scrollbar-thumb {
    background: rgba(150, 150, 150, 1);
    border-radius: 15px;
}

.optionsInputsContainer::-webkit-scrollbar-thumb:hover {
    background: rgb(85, 85, 85);
}

.injuryTypeGeneratorOptionChooserElement {
    position: relative;
    margin-top: 10px;
}

.newOptionButtonContainer {
    cursor: pointer;
    position: relative;
    left: calc(50% - 15px);
    /* left: 10px; */
    /* width: 30px; */
    margin-top: 20px;
    margin-bottom: 15px;
    height: 30px;
    
}

.btnSaveOptions {
    height: 20px;
    width: 20px;
    position: absolute;
    right: 15px;
    bottom: 15px;
}

.btnCancelOptions {
    height: 20px;
    width: 20px;
    position: absolute;
    left: 15px !important;
    bottom: 15px !important;
}

.optionsFooterContainer {
    position: relative;
    height: 35px;
}

/* #endregion Option chooser */

/* #region Buttons */

.btnEditOptions {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 30px !important;
    top: 4px !important;
    z-index: 10;
}

.injuryGeneratorClose {
    height: 16px;
    width: 16;
    position: absolute;
    top: 10px !important;
    right: 10px !important;
}

.propertyDeleteButton {
    position: absolute !important;
    right: 10px !important;
    top: 0px !important;
    height: 16px;
    width: 16px;
}

.optionDeleteButton {
    width: 16px;
    height: 16px;
    position: relative;
    left: 10px !important;
    top: 3px;
}

.btnCreateInjury {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: rgba(48, 167, 44, 0.8) !important;
}

/* #endregion Buttons */

/* #region Toast Alerts */

.toastInjuryTypeGenerator_alert {
    text-align: center;
    background-color: green;
}

.toastInjuryTypeGenerator_error {
    text-align: center;
}

/* #endregion Toast Alerts */

/* #region General */

img, div {
    outline: 0;
}

.scrollable {
    height: auto;
    overflow: hidden;
    overflow-y: auto;
}

.generatorHeaderField {
    color: rgb(100,100,100);
    opacity: 0.7;
    font-size: 18px;
}

.tertiaryHeader {
    background-color: rgb(60,60,60);
    color: rgb(160,160,160) !important;
    height: auto;
    min-height: 30px;
    font-size: 18px;
    vertical-align: middle;
    
}

.secondaryContainer {
    border: 1px solid rgb(80,80,80);
    background-color: rgb(45,45,45);
    margin: 4px;
}

.tertiaryContainer {
    border: 1px solid rgb(80,80,80);
    margin: 4px;
}

.propertyItemsContainer {
    position: relative;
    margin-top: 10px;
}

.generatorInput {

    position: relative;
    border: none;
    border-radius: 3px;
    padding-left: 10px;
    padding-right: 10px;
    width: 90px;
    margin: 0;
    height: 20px;
    line-height: 22px;
    font-size: 14px;
    background-color: rgba(0,0,0,0);
    border: 1px solid rgba(80,80,80,0.5);
    color: rgb(160,160,160) !important;
    display: inline-block; 
}

.generatorInput:hover {
    background-color: rgb(50,50,50);
}

.summaryContainer{
    min-height: 20px;
}

.summaryHeader {
    text-align: left;
    margin-left: 30px;
    margin-top: 10px;
    color: rgba(160,160,160, 0.7);
    font-size: 18px;
}

.generatedSummary {

    text-align: left;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 12px;
    margin-bottom: 15px;
    /* border: 1px solid rgba(255,255,255,0.1); */
    border-radius: 4px;
    padding: 2px 0px;
    font-size: 15px;
    line-height: 20px;
}

.generatedSummarySuccess {
    color: rgb(160,160,160)
}

.generatedSummaryFailed {
    color: rgba(233, 49, 49, 0.7);
}

.propertyTypeHeader {
    position: sticky;
    text-align: left;
    padding-left: 15px;
}

.injuryGeneratorButton {
    transition: ease-in-out 0.1s all;
    cursor: pointer;
}

.injuryGeneratorButton:hover {
    transform: scale(1.3);
}

.btnOval {
    position: absolute;
    padding: 5px;
    border-radius: 5px;
    background-color: teal;
    transition: 0.15s all ease-in-out;
    opacity: 0.5;
}

.btnOval:hover {    
    opacity: 0.8;
    transform: scale(1.01) 
}

.btnOval:active {
    opacity: 0.7;
}

.txtLabel {
    color: rgb(100,100,100);
}

/* #endregion General */

/* #region OPTION FIELD SELECT STYLING */

.generatorSelect-container {
    position: relative;    
    width: 120px;
    height: 25px;
    z-index: 6;
    cursor: pointer;
}

.generatorSelect__control {
    height: 25px !important;
    min-height: 25px !important;
    background-color: rgba(0,0,0,0) !important;
    border: 1px solid rgba(80,80,80,0.5) !important;
    color: rgb(160,160,160) !important;
    border-radius: 3px !important;
    padding: 0 !important;
    transition: all 100ms ease-in-out;
    cursor: pointer;
}

.generatorSelect__control:hover {
    background-color: rgb(50,50,50) !important;
}

.generatorSelect__control--is-focused {
    border: 1px solid rgb(80,80,80) !important;
    box-shadow: none !important;
    background-color: rgba(120,120,120,0.2) !important;
}

.generatorSelect__value-container {
    height: 24px !important;
    border-radius: 0 !important;
    padding: 2px !important;
    position: relative !important;
    top: -2px !important;
    margin-top: 0 !important;
    cursor: pointer;
}

.generatorSelect__input, .generatorSelect__single-value {
    height: 24px !important;
    line-height: 24px !important;
    color: rgba(255,255,255,0.7) !important;
    font-size: 14px !important;
    border-radius: 0 !important;
    cursor: pointer !important;
    padding: 0 !important;
}

.generatorSelect__input {
    position: absolute;
    left: 4px;
    top: 0;
}

.generatorSelect__indicator {
    height: 24px !important;
    padding: 2px !important;
    opacity: 0.5 !important;
    cursor: pointer;
}

.generatorSelect__indicator-separator {
    /* Hide it */
    background-color: rgba(0,0,0,0) !important;
}

.generatorSelect__dropdown-indicator {
    top: -2px;
    /* Hide it */
    display: none;
    background-color: rgba(0,0,0,0) !important;

}

.generatorSelect__menu, .generatorSelect__menu-list {
    margin-top: 0 !important;
    background-color: rgba(40,40,40,0.6) !important;
    border-radius: 4px !important;
    color: rgba(255,255,255,0.7) !important;
}

.generatorSelect__menu-list {
    border: 1px solid rgb(60,60,60) !important;
}

.generatorSelect__option {
    padding: 0 8px !important;
    color: rgb(160,160,160) !important;
    height: 28px !important;
    line-height: 28px !important;
    font-size: 14px !important;
    border-radius: 0 !important;
    transition: all 50ms ease-in-out;
    cursor: pointer !important;
    overflow: hidden;
}

.generatorSelect__option:hover, .generatorSelect__option--is-focused {
    background-color: rgba(80,80,80,0.8) !important;
}

.generatorSelect__option--is-selected {
    background-color: rgba(255, 136, 0, 0.8)!important;
    color: black !important;
}

.generatorSelect__placeholder {
    line-height: 28px !important;
    font-size: 14px !important;
}

 /* Scroll Bar */
.generatorSelect__menu-list::-webkit-scrollbar {
    width: 5px;
    height: 0px;
  }
.generatorSelect__menu-list::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 1);
    border-radius: 15px;
}
.generatorSelect__menu-list::-webkit-scrollbar-thumb {
    background: rgba(150, 150, 150, 1);
    border-radius: 15px;
}
.generatorSelect__menu-list::-webkit-scrollbar-thumb:hover {
    background: rgb(85, 85, 85);
}

/* #endregion OPTION FIELD SELECT STYLING */

/* #region confirm dialog */

.confirmDialogBackground {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    background-color: rgba(0,0,0,0.5);
}


.confirmDialog {
    position: absolute;
    width: 300px;
    height: 90px;
    left: calc(50% - 450px/2);
    top: calc(40% - 90px/2);
    background-color: rgb(45,45,45);
    z-index: 102;
}

.confirmDialogTitle {
    position: relative;
    font-size: 18px;
    margin-bottom: 15px;
}

.confirmDialogButtonContainer {
    position: relative;
}

.btnConfirmDialog_no {
    left: 80px;
    color: rgb(250,250,250);
    font-size: 18px;
    background-color: rgba(255, 135, 37, 0.8) !important;
}

.btnConfirmDialog_yes {
    right: 80px;
    color: rgb(250,250,250);
    font-size: 18px;
    background-color: rgba(48, 167, 44, 0.8) !important;
}

/* #endregion confirm dialog */