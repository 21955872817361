/*
Developed by Aptus Engineering, Inc. <https://aptusai.com>
See LICENSE.md file in project root directory
*/

.unAuthRoot {
    position: fixed;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgb(80, 80, 80);
}

.unAuthContainer {
    position: relative;
    left: 20%;
    top: 50px;
    width: 60%;
    height: auto;

    background-color: rgb(100, 100, 100);
    border-radius: 4px;

}


.unAuthLogoContainer {
    position: relative;
    left: 20px;
    margin-top: 20px;
    width: 100px;
    height: auto;


}

.unAuthLogo {

    width: 100%;
    

}

.unAuthTextContainer {

    position: absolute;

    left: 30%;
    top: 11%;

}

.unAuthHeader {
    position: relative;
    font-size: 30px;
    color: rgba(255, 255, 255, 0.9);
}

.unAuthText {
    position: relative;
    top: 10px;
    color: rgba(255, 255, 255, 0.7);
}

