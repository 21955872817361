/*
Developed by Aptus Engineering, Inc. <https://aptusai.com>
See LICENSE.md file in project root directory
*/

.label3DContainer {
    position: absolute;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
    font-family: 'Barlow';
}

.label3D {
    position: absolute;
    color: rgb(0,220,220);
    text-align: center;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    line-height: 30px;
    cursor: none;

    padding: 0 5px;
    transform: translateX(-5px);

    font-size: 16px;
    background-color: rgba(0,0,0,0);
    border: none;
    z-index: 10;
    
}

.label3DDouble {
    /* position: relative; */
    color: rgb(0,220,220);
    text-align: center;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    line-height: 30px;
    min-height: 30px;
    cursor: none;

    font-size: 16px;
    background-color: rgba(0,0,0,0);
    border: none;
    opacity: 0;
    z-index: 0;
    
}

.label3D:hover {
    color: rgb(160,160,160);
}

.label3D:focus {
    background-color: rgba(255,255,255,0.1);
    border: none;
    box-shadow: none;
    outline: none;
    cursor: default;
}


.label3DDelete {

}

.label3DDelete:hover {
    color: rgba(255, 70, 70, 0.7);
    /* cursor: pointer; */
}