/*
Developed by Aptus Engineering, Inc. <https://aptusai.com>
See LICENSE.md file in project root directory
*/

.viewerConfigMenuContainer {

    width: 350px;
    height: 460px; 
    background-color: rgba(20, 20, 20, 0.95);
    border-radius: 4px;

    position: absolute;
    top: calc(50% - 200px);
    left: calc(50% - 150px);
    color: rgba(255,255,255,0.7);

    z-index: 2;
}

.viewerConfigHeader {

    width: 90%;
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
    opacity: 0.6;
    color: white;


}

.configSidePanel {
    position: absolute;
    left: 20px;
    top: 50px;
    width: 40px;
    height: 308px;

    background-color: rgba(60, 60, 60, 0.5);
    border-radius: 4px;

    /* background-color: rgba(); */
}

.sidePanelButtonContainer {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    transition: all 0.1s ease-in-out;
    border-radius: 4px;
}

.sidePanelButtonContainer:hover {
    background-color: rgba(80, 80, 80, 0.5);
}

.sidePanelButtonContainerActive {
    background-color: rgba(80, 80, 80, 0.5);
}

.sidePanelButton {
    width: 100%;
    height: auto;
    transition: all 0.1s ease-in-out;

}

.sidePanelButton:hover {
    opacity: 1;
    transform: scale(1.08);
}

/* Quadrant Selector */

.quadrantSelector {

    position: absolute;
    right: 40px;
    top: 50px;
    width: 225px;
    height: 200px;
    background-color: rgba(255,255,255,0.0);

    border-radius: 4px;
    /* overflow: hidden; */
}

.selectorQuadrant {
    position: absolute;
    border: 1px solid rgba(240,240,240, 0.8);
    border-radius: 4px;
}
.selectorQuadrant:hover {
    background-color: rgba(255, 255, 255, 0.4);
}
.selectorQuadrantActive {
    background-color: rgba(255, 255, 255, 0.4);
}

.viewerConfigClose {

    position: absolute;
    top: 12px;
    right: 10px;
    width: 20px;
    opacity: 0.4;
    transition: ease-in-out 0.1s all;
}

.viewerConfigClose:hover {
    opacity: 1.0;
    width: 22px;
    right: 9px;
    top: 11px;
}

.viewerEditor { 
    position: absolute;
    right: 10px;
    top: 260px;
    width: 255px;
    height: 100px;
    /* background-color: black; */
}



.propertyRow {
    width: 100%;
    height: 30px;

    /* background-color: white; */
    margin-bottom: 5px;
}


.propertyRowLabel {
    line-height: 30px;
    display: inline-block;
    
}

.propertyRowInput {
    position: absolute;
    
    left: 75px !important; 
    
    width: 113px;
    height: 24px !important;
    
    background-color: rgba(0,0,0,0) !important;
    color: rgba(255,255,255,0.7) !important;
    border: 1px solid rgba(80,80,80,0.5) !important;
    border-radius: 3px !important;

    z-index: 8;
    cursor: pointer;
    padding: 0;
    padding-left: 4px;
    margin: 0;
    margin-top: 3px;
}



.visibilityToolbar {

    position: absolute;
    /* right: 135px; */
    left: 100px;
    bottom: 50px;
    width: auto;
    /* height: 28px; */

    border-radius: 4px;
    overflow: hidden;
    background-color: rgba(80, 80, 80, 0.4);
    /* border: 1px solid rgba(80,80,80,0.5) !important; */

}


.visibilityButtonContainer {
    float: right;
    width: 28px;
    height: 28px;
    margin: 0px;
    padding: 0px;

    background-color: rgba(0, 0, 0, 0);
    
    transition: 0.15s all ease-in-out;
}

.visibilityButtonContainer:hover {

}

.visibilityButtonContainerActive {
    /* background-color: rgba(120, 120, 120, 0.7); */
    /* border: 1px solid rgba(166, 208, 233, 0.5) !important; */
}

.visibilityButton {
    width: 28px;
    height: 28px;
    opacity: 0.2;
    transition: 0.15s all ease-in-out;
}

.visibilityButton:hover {
    
    opacity: 0.7;
    transform: scale(1.10) 
}

.visibilityButtonActive {

    opacity: 0.7;

}


/* Save Config Button */

.saveConfigButton {

    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: rgba(48, 167, 44, 0.6) !important;
    padding: 4px;
    border-radius: 4px;
    opacity: 0.7;
    cursor: pointer;
    transition: 0.1s ease-in-out all;
}

.saveConfigButton:hover {
    opacity: 1.0;
}



/* FIELD SELECT STYLING */

.propertySelect-container {

    position: absolute;
    /* top: -20px; */
    bottom: calc(100% - 4px);
    left: 75px;
    
    width: 120px;
    height: 25px;
    z-index: 8;
    cursor: pointer;
}

.propertySelect__control {
    height: 25px !important;
    min-height: 25px !important;
    background-color: rgba(0,0,0,0) !important;
    border: 1px solid rgba(80,80,80,0.5) !important;
    border-radius: 3px !important;
    padding: 0 !important;
    transition: all 100ms ease-in-out;
    cursor: pointer;
}

.propertySelect__control:hover {
    background-color: rgba(120,120,120,0.2) !important;
}

.propertySelect__control--is-focused {
    border: 1px solid rgb(80,80,80) !important;
    box-shadow: none !important;
    background-color: rgba(120,120,120,0.2) !important;
}

.propertySelect__value-container {
    height: 24px !important;
    border-radius: 0 !important;
    padding: 2px !important;
    position: relative !important;
    top: -2px !important;
    margin-top: 0 !important;
    cursor: pointer;
}

.propertySelect__input, .propertySelect__single-value {
    height: 24px !important;
    line-height: 24px !important;
    color: rgba(255,255,255,0.7) !important;
    font-size: 14px !important;
    border-radius: 0 !important;
    cursor: pointer !important;
    padding: 0 !important;
}

.propertySelect__input {
    position: absolute;
    left: 4px;
    top: 0;
}

.propertySelect__indicator {
    height: 28px !important;
    padding: 4px !important;
    opacity: 0.5 !important;
    cursor: pointer;
}

.propertySelect__indicator-separator {
    /* Hide it */
    background-color: rgba(0,0,0,0) !important;
}

.propertySelect__menu, .propertySelect__menu-list {
    margin-top: 0 !important;
    background-color: rgba(40,40,40,0.6) !important;
    border-radius: 4px !important;
    color: rgb(160,160,160) !important;
}

.propertySelect__menu-list {
    border: 1px solid rgb(60,60,60) !important;
}

.propertySelect__option {
    padding: 0 8px !important;
    color: rgb(160,160,160) !important;
    height: 28px !important;
    line-height: 28px !important;
    font-size: 14px !important;
    border-radius: 0 !important;
    transition: all 50ms ease-in-out;
    cursor: pointer !important;
    overflow: hidden;
}

.propertySelect__option:hover, .propertySelect__option--is-focused {
    background-color: rgba(80,80,80,0.8) !important;
}

.propertySelect__option--is-selected {
    background-color: rgba(255, 136, 0, 0.8)!important;
    color: black !important;
}

.propertySelect__placeholder {
    line-height: 28px !important;
    font-size: 14px !important;

}

 /* Scroll Bar */
.propertySelect__menu-list::-webkit-scrollbar {
    width: 5px;
    height: 0px;
  }
 .propertySelect__menu-list::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 1);
    border-radius: 15px;
  }
 .propertySelect__menu-list::-webkit-scrollbar-thumb {
    /* background: rgba(255, 136, 0, 0.8); */
    background: rgba(150, 150, 150, 1);
    border-radius: 15px;
  }
 .propertySelect__menu-list::-webkit-scrollbar-thumb:hover {
    background: rgb(85, 85, 85);
  }
