/*
Developed by Aptus Engineering, Inc. <https://aptusai.com>
See LICENSE.md file in project root directory
*/

.dicomViewerContainer{
    
    /* width: 100px;
    height: 100px;
    background-color: black; */

    left: -2px;
    top: 32px;
    bottom: 0;
    right: 0;
    position: fixed;
    background-color: rgb(36, 36, 36);

}