/*
Developed by Aptus Engineering, Inc. <https://aptusai.com>
See LICENSE.md file in project root directory
*/


/* Dropdown Containers */


.injuryLocationDropContainer {

    width: calc(100% - 20px);
    height: auto;
    min-height: 30px;

    margin-left: 10px;
    margin-bottom: 15px;

}

.injuryDropContainer {
    position: relative;
    width: calc(100% - 8px);
    margin-bottom: 5px;
    margin-top: 5px;
}


/* Injury Summary */
.injurySummary {
    margin-top: 8px;
    width: calc(100% - 22px);
    min-width: calc(100% - 22px);
    max-width: calc(100% - 22px);
    min-height: 60px;
    background-color: rgba(0,0,0,0);
    border: 1px solid rgb(60,60,60);
    border-radius: 3px;
    transition: ease-in-out 0.1s all;
    color: rgb(160,160,160);
    font-size: 14px;
    line-height: 18px;
    padding: 5px;
}

.injurySummary:hover {
    background-color: rgb(50,50,50);
}

.injurySummaryRegenerate {
    position: absolute;
    left: 20px;
    margin-top: 12px;
    width: 18px;
    height: 18px;
    transition: ease-in-out 0.1s all;
    cursor: pointer;
}

.injurySummaryRegenerate:hover {
    transform: scale(1.3);
}

/* Injury Property Rendering */
.injuryFieldContainer {
    position: relative;
    padding: 0;
    margin: 0;
    height: 30px;
    line-height: 30px;
}


.propertyField {
    float: left;
    color: rgb(100,100,100);
}

.booleanFieldValue {
    float: right;
    margin-right: 74px;
}

.booleanFieldSwitch {
    vertical-align: middle;
}


.textFieldValue {
    opacity: 0.8;
    float: right;
    margin-right: 5px;
    color: rgb(160,160,160) !important;
}

.textFieldInput {
    border: none;
    border-radius: 3px;
    padding-left: 10px;
    padding-right: 10px;
    width: 90px;
    margin: 0;
    height: 20px;
    line-height: 22px;
    font-size: 13px;
    border: 1px solid rgba(80,80,80,0.5);
    background-color: rgba(0,0,0,0);
    color: rgb(160,160,160);
    display: inline-block;
}

.injuryMetricField {
    position: absolute;
    float: left;
    color: rgb(100,100,100);
    cursor: default;
}

.injuryMetricField_metricName {
    display: inline-block;
    margin-left: 0px;
}

.injuryMetricField_seriesName {
    display: inline;
    position: relative;
    margin-left: 10px;
    font-size: 11px;
    opacity: 0.7;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis
}

.injuryMetricValue {
    margin-right: 55px;
    float: right;
    width: auto;
    max-width: 80px;
}

.injuryMetricEdit {
    position: absolute;
    right: 32px;
    top: 6px;
    width: 16px;
    height: 16px;
    transition: ease-in-out 0.1s all;
    cursor: pointer;
}

.injuryMetricDelete {
    position: absolute;
    right: 6px;
    top: 6px;
    width: 16px;
    height: 16px;
    transition: ease-in-out 0.1s all;
    cursor: pointer;
}

.injuryMetricAdd {
    position: absolute;
    right: 6px;
    top: 6px;
    width: 20px;
    height: 20px;
    transition: ease-in-out 0.1s all;
    cursor: pointer;
}

.injuryMetricEdit:hover, .injuryMetricDelete:hover, .injuryMetricAdd:hover{
    transform: scale(1.3);
}


/* OPTION FIELD SELECT STYLING */

.optionSelect-container {
    float: right;
    margin-right: 5px;
    margin-top: 4px;
    width: 110px;
    height: 25px;
    cursor: pointer;
}

.optionSelect__control {
    height: 25px !important;
    min-height: 25px !important;
    background-color: rgba(0,0,0,0) !important;
    border: 1px solid rgba(80,80,80,0.5) !important;
    border-radius: 3px !important;
    padding: 0 !important;
    transition: all 100ms ease-in-out;
    cursor: pointer;
    color: rgb(160,160,160) !important;
}

.optionSelect__control:hover {
    background-color: rgb(50,50,50) !important;
}

.optionSelect__control--is-focused {
    border: 1px solid rgb(80,80,80) !important;
    box-shadow: none !important;
    background-color: rgba(120,120,120,0.2) !important;
}

.optionSelect__value-container {
    height: 24px !important;
    border-radius: 0 !important;
    padding: 2px !important;
    position: relative !important;
    top: -2px !important;
    margin-top: 0 !important;
    cursor: pointer;
    color: rgb(160,160,160) !important;
}

.optionSelect__input, .optionSelect__single-value {
    height: 24px !important;
    line-height: 24px !important;
    color: rgb(0,0,0) !important;
    font-size: 14px !important;
    border-radius: 0 !important;
    cursor: pointer !important;
    padding: 0 !important;
    color: rgb(160,160,160) !important;
}

.optionSelect__input {
    position: absolute;
    left: 4px;
    top: 0;
}

.optionSelect__indicator {
    height: 24px !important;
    padding: 2px !important;
    opacity: 0.5 !important;
    cursor: pointer;
}

.optionSelect__indicator-separator {
    /* Hide it */
    background-color: rgba(0,0,0,0) !important;
}

.optionSelect__menu, .optionSelect__menu-list {
    margin-top: 0 !important;
    background-color: rgba(40,40,40,0.6) !important;
    border-radius: 4px !important;
    color: rgb(160,160,160) !important;
}

.optionSelect__menu-list {
    border: 1px solid rgb(60,60,60) !important;
}

.optionSelect__option {
    padding: 0 8px !important;
    color: rgb(160,160,160) !important;
    height: 28px !important;
    line-height: 28px !important;
    font-size: 14px !important;
    border-radius: 0 !important;
    transition: all 50ms ease-in-out;
    cursor: pointer !important;
    overflow: hidden;
}

.optionSelect__option:hover, .optionSelect__option--is-focused {
    background-color: rgba(80,80,80,0.8) !important;
}

.optionSelect__option--is-selected {
    background-color: rgba(255, 136, 0, 0.8)!important;
    color: black !important;
}

.optionSelect__placeholder {
    line-height: 28px !important;
    font-size: 14px !important;

}

 /* Scroll Bar */
.optionSelect__menu-list::-webkit-scrollbar {
    width: 5px;
    height: 0px;
  }
 .optionSelect__menu-list::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 1);
    border-radius: 15px;
  }
 .optionSelect__menu-list::-webkit-scrollbar-thumb {
    /* background: rgba(255, 136, 0, 0.8); */
    background: rgba(150, 150, 150, 1);
    border-radius: 15px;
  }
 .optionSelect__menu-list::-webkit-scrollbar-thumb:hover {
    background: rgb(85, 85, 85);
  }


  /* Cloud save tip */

  .cloudSaveTip {
    margin-top: 10px;
    margin-left: 5px;
    font-size: 12px;
    opacity: 0.7;
  }