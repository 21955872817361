/*
Developed by Aptus Engineering, Inc. <https://aptusai.com>
See LICENSE.md file in project root directory
*/

.rwrPage {
    position: relative;
    background-color: white;
    width: 765px;
    min-height: 660px;
    margin: 10px auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgb(0,0,0);

    padding-top: 250px;   /* header */
    padding-bottom: 80px; /* footer */
}

.rwrPageFirst {
    margin-top: 40px;
}

.rwrPageLast {
    margin-bottom: 40px;
}

.rwrHeader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 240px;
    /* border-bottom: 1px solid red; */
}

.rwrHeaderLetterhead {
    width: calc(100% - 160px);
    height: 60px;
    background-color: rgb(220,220,220);
    background-image: url('/resources/multus/multus-logo.png');
    background-size: auto 50px;
    background-repeat: no-repeat;
    background-position: 80px 45px;
    font-family: 'Barlow', sans-serif;
    color: rgb(40,40,40);
    float: right;
    text-align: right;
    padding-top: 50px;
    padding-right: 80px;
    padding-left: 80px;
    font-size: 13px;
    margin-bottom: 30px;
}

.rwrFooter {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 80px;
    padding-top: 10px;
    padding-left: 80px;
    padding-right: 80px;
    text-align: right;
    font-size: 13px;
    font-style: italic;
    font-weight: bold;
    /* border-top: 1px solid red; */
}

.rwrContent {
    width: calc(100% - 160px);
    padding: 0 80px;
    /* border-bottom: 1px solid green; */
}

/* May make sense to move these to a separate file that can be imported in run-time (see stax-ui template imports) */
.rwrHeaderAddress {
    font-family: 'Barlow', sans-serif;
}

.rwrHeaderLineLeft {
    width: 235px;
    height: 4px;
    background-color: rgb(230,155,30);
    position: absolute;
    left: 0;
    top: 110px;
}

.rwrHeaderLineRight {
    width: calc(100% - 238px);
    height: 4px;
    background-color: rgb(100,100,100);
    position: absolute;
    right: 0;
    top: 110px;
}

.patientMeta {
    width: calc(100% - 160px);
    font-size: 13px;
    border-bottom: 2px solid rgb(120,120,120);
    padding-bottom: 20px;
    margin: 0 80px;
    line-height: 20px;
}

.patientMetaField {
    clear: both;
}

.patientMetaLeft {
    float: left;
    width: calc(60% - 30px);
    margin-right: 30px;
}

.patientMetaRight {
    float: right;
    width: calc(40% - 1px);
}

.patientMetaLabel {
    float: left;
    width: 100px;
    font-weight: bold;
}

.patientMetaLabelLong {
    width: 150px;
}


.reportMetaField {
    width: 100%;
    font-family: 'Arial', 'sans-serif';
    color: black;
    text-decoration: none;
    font-size: 14px;
    text-align: left;
    margin: 0;
    line-height: 30px;
    clear: both;
}

.reportMetaLabel {
    float: left;
    width: 120px;
    font-weight: bold;
}

.reportMetaValue {
    float: left;
    font-weight: normal;
}

.reportSection {
    width: 100%;
    clear: both;
    margin-top: 10px;
}

.reportLoc, .reportGlobal {
    width: 100%;
    color: black;
    font-weight: normal;
    text-decoration: none;
    font-size: 14px;
    text-align: justify;
    margin: 0;
    line-height: 24px;
    clear: both;
}


.reportLocLabel {
    width: 60px;
    float: left
}

.reportLocSummary {
    width: calc(100% - 60px);
    float: left;
    margin-bottom: 10px;
}

.reportLocLabelText, .reportLocSummary {
    cursor: pointer;
}

.reportLocLabelText:hover {
    background-color: rgba(240,200,0,0.5);
    border-radius: 4px;
}

.reportLocSummary_single:hover {
    /* color: rgba(50,50,200,0.8) !important; */
    background-color: rgba(0,240,240,0.3);
    border-radius: 4px;
}



.reportAttribute {
    width: 100%;
    color: black;
    font-weight: normal;
    text-decoration: none;
    font-size: 11px;
    text-align: left;
    margin: 0;
    line-height: 16px;
    clear: both;
    margin-top: 40px;
    margin-bottom: 20px;
}

.reportDicom {
    margin: 5px;
    margin-top: 20px;
    float: left;
}

.reportDicom > img {
    width: 230px;
    height: 230px;
}

.reportDicom > h1 {
    margin-bottom: 40px;
}

.reportSection > h1 {
    color: black;
    font-weight: bold;
    text-decoration: none;
    font-size: 14px;
    text-align: left;
    margin: 0;
    line-height: 30px;
    clear: both;
}

.reportLine {
    margin-bottom: 10px;
}

.signReport {
    height: 24px;
    line-height: 24px;
    margin-top: -26px;
    padding: 0 10px;
    color: white;
    float: right;
    /* clear: both; */
    background-color: rgb(40,100,140);
    border: 1px solid rgb(40,140,180);
    border-radius: 7px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.signReport:hover {
    background-color: rgb(40,140,180);
    border: 1px solid rgb(40,200,240);
}