/*
Developed by Aptus Engineering, Inc. <https://aptusai.com>
See LICENSE.md file in project root directory
*/


.generatorPage {
    overflow: hidden;
}

.generatorContainer {
    position: absolute;
    top: 32px;
    left: 0;
    width: 100%;
    height: calc(100% - 32px);
    background-color: rgb(36, 36, 36);
}

.generatorContainerViewerMode {
    left: 30%;
    width: 70%;
}

.reportEditorContainer {

    position: absolute;
    top: 0;
    left: 0;
    width: 366px;
    bottom: 0;
    border-left: 2px solid rgb(50,50,50);
    border-right: 2px solid rgb(50,50,50);
    background-color: rgb(36,36,36);
    overflow-y: auto;
    
}

/* Scroll Bar */
.reportEditorContainer::-webkit-scrollbar, .injurySummary::-webkit-scrollbar {
    width: 5px;
    height: 0px;
    
}

.reportEditorContainer::-webkit-scrollbar-track, .injurySummary::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 1);
  border-radius: 15px;
}

.reportEditorContainer::-webkit-scrollbar-thumb, .injurySummary::-webkit-scrollbar-thumb {
  background: rgba(150, 150, 150, 1);
  border-radius: 15px;
}

.reportEditorContainer::-webkit-scrollbar-thumb:hover, .injurySummary::-webkit-scrollbar-thumb:hover {
  background: rgb(85, 85, 85);
}

.reportPreviewContainer {

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(100% - 370px);
    background-color: rgb(50,50,50);
    overflow-y: auto;

}


/* Scroll Bar */
.reportPreviewContainer::-webkit-scrollbar {
    width: 8px;
    height: 0px;
    
  }
 .reportPreviewContainer::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 1);
    border-radius: 15px;
  }
 .reportPreviewContainer::-webkit-scrollbar-thumb {
    background: rgba(150, 150, 150, 1);
    border-radius: 15px;
  }
 .reportPreviewContainer::-webkit-scrollbar-thumb:hover {
    background: rgb(85, 85, 85);
  }


.reportEditorSectionHeader {
  font-size: 20px;
  line-height: 40px;
  color: rgb(160,160,160);
  padding: 0 10px;
  margin: 10px 2px;
  border-bottom: 1px solid rgb(60,60,60);
}