
/* Developed by Aptus Engineering, Inc. <https://aptusai.com>
See LICENSE.md file in project root directory */

/* ConfirmButton */

.confirmBtnBackground {
    position: relative;
    background-color: rgba(0,0,0,0);
    cursor: pointer;
}

.confirmBtnContainer {
    position: relative;
    float: right;
    background-color: rgb(60,60,60);
    border-radius: 50px;
    transition: all 0.1s ease-in-out;
}

.confirmBtnContainer:hover {
    transition: all 0.1s ease-in-out;
    background-color: rgba(80,80,80);
}

.confirmBtnTxt {
    position: relative;
    color: rgb(160,160,160);
    font-size: 16px;
    text-align: center;    
    user-select: none;
    transition: all 0.1s ease-in-out;
}

.unclickedContainer:hover {
    /* transform: scale(1.3); */
    transition: all 0.1s ease-in-out;
}