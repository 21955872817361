/*
Developed by Aptus Engineering, Inc. <https://aptusai.com>
See LICENSE.md file in project root directory
*/


.viewerSelectContainer {
    right: 0px;
    position: absolute;
    background-color: rgba(50,50,50,0.8);
    opacity: 1.0;
    border: 1px solid rgb(80,80,80);
    border-radius: 4px;
    width: 172px;
    height: 30px;
    top: 37px;
    z-index: 100;
    padding-left: 4px;
    padding-top: 2px;
}