/*
Developed by Aptus Engineering, Inc. <https://aptusai.com>
See LICENSE.md file in project root directory
*/

.dicomViewer{
    overflow: hidden;
    position: absolute;
    background-color: rgba(0,0,0,0);
    transition: background-color 0.1s ease-in-out;
    cursor: none;
}

.dicomViewer:hover {
    background-color: rgb(42,42,42);
}

.dicomViewerLoading {
    cursor: auto;
}

/* Dicom Image Workspace */
.workspace {
    position: relative;
    overflow: auto;
    touch-action: none;
    cursor: none;

}

.dicomImage {
    width:100%;
    height: auto;
    z-index: 2;
    margin: 0;
    padding: 0
}

.dicomSeriesSelect-container {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 160px;
    height: 30px;
    z-index: 6;
    cursor: pointer;
}

.dicomSeriesSelect__control {
    height: 28px !important;
    min-height: 28px !important;
    background-color: rgba(0,0,0,0) !important;
    border: 1px solid rgba(80,80,80,0.5) !important;
    border-radius: 3px !important;
    padding: 0 !important;
    transition: all 100ms ease-in-out;
    cursor: pointer;
}

.dicomSeriesSelect__control:hover {
    background-color: rgba(120,120,120,0.2) !important;
}

.dicomSeriesSelect__control--is-focused {
    border: 1px solid rgb(80,80,80) !important;
    box-shadow: none !important;
    background-color: rgba(120,120,120,0.2) !important;
}

.dicomSeriesSelect__value-container {
    height: 24px !important;
    border-radius: 0 !important;
    padding: 2px !important;
    position: relative !important;
    margin-top: 0 !important;
    cursor: pointer;
}

.dicomSeriesSelect__input, .dicomSeriesSelect__single-value {
    height: 24px !important;
    line-height: 24px !important;
    color: rgb(160,160,160) !important;
    font-size: 14px !important;
    border-radius: 0 !important;
    cursor: pointer !important;
    padding: 0 !important;
}

.dicomSeriesSelect__input {
    position: absolute;
    left: 4px;
    top: 0;
}

.dicomSeriesSelect__indicator {
    height: 28px !important;
    padding: 4px !important;
    opacity: 0.5 !important;
    cursor: pointer;
}

.dicomSeriesSelect__indicator-separator {
    /* Hide it */
    background-color: rgba(0,0,0,0) !important;
}

.dicomSeriesSelect__menu, .dicomSeriesSelect__menu-list {
    margin-top: 0 !important;
    background-color: rgba(40,40,40,0.6) !important;
    border-radius: 4px !important;
    color: rgb(160,160,160) !important;
}

.dicomSeriesSelect__menu-list {
    border: 1px solid rgb(60,60,60) !important;
}

.dicomSeriesSelect__option {
    padding: 0 8px !important;
    color: rgb(160,160,160) !important;
    height: 28px !important;
    line-height: 28px !important;
    font-size: 14px !important;
    border-radius: 0 !important;
    transition: all 50ms ease-in-out;
    cursor: pointer !important;
    overflow: hidden;
}

.dicomSeriesSelect__option:hover, .dicomSeriesSelect__option--is-focused {
    background-color: rgba(80,80,80,0.8) !important;
}

.dicomSeriesSelect__option--is-selected {
    background-color: rgba(255, 136, 0, 0.8)!important;
    color: black !important;
}

 /* Scroll Bar */
.dicomSeriesSelect__menu-list::-webkit-scrollbar {
    width: 5px;
    height: 0px;
  }
 .dicomSeriesSelect__menu-list::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 1);
    border-radius: 15px;
  }
 .dicomSeriesSelect__menu-list::-webkit-scrollbar-thumb {
    /* background: rgba(255, 136, 0, 0.8); */
    background: rgba(150, 150, 150, 1);
    border-radius: 15px;
  }
 .dicomSeriesSelect__menu-list::-webkit-scrollbar-thumb:hover {
    background: rgb(85, 85, 85);
  }


.viewerLoadingIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -60px;
    margin-top: -60px;
}
.viewerTogglebar {
    position: absolute;
    left: calc(50% - 2*28px);
    bottom: 4px;
    border: none !important;
    background-color: rgba(80, 80, 80, 0.4);
    border-radius: 3px;
    z-index: 6;
    transition: 0.15s all ease-in-out;
    cursor: pointer;
}

.toggleButtonContainer {
    float: right;
    width: 28px;
    height: 28px;
    margin: 0px;
    padding: 0px;
    background-color: rgba(0, 0, 0, 0);
    transition: 0.15s all ease-in-out;
}

.toggleButton {
    width: 28px;
    height: 28px;
    opacity: 0.2;
    transition: 0.15s all ease-in-out;
}

.toggleButton:hover {
    opacity: 0.7;
    transform: scale(1.25) 
}

.toggleButtonActive {
    opacity: 1.0;
}


/* Anatomy Label */

.anatomyLabel {
    position: absolute;
    color: rgb(0,220,220);
    text-align: center;
    width: auto;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    overflow: hidden;
    line-height: 30px;
    cursor: pointer;

}
