/*
Developed by Aptus Engineering, Inc. <https://aptusai.com>
See LICENSE.md file in project root directory
*/

.header {
    height: 32px;
    line-height: 32px;
    padding: 0;
    padding-left: 42px;
    padding-right: 16px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: rgb(60,60,60);
    color: rgb(220,220,220);
    border-bottom: 2px solid rgb(120,120,120);
    min-width: 400px;
}

.headerLogo {
    position: absolute;
    top: -2px;
    left: 80px;

    height: 20px;
    margin-top: 8px;
    margin-left: -64px;
    
    z-index: 100;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.header > img:hover {
    transform: scale(1.2);
}

.headerButtonContainer {
    width: 28px;
    height: 28px;
    float: left;
    transition: all 0.1s ease-in-out;
    background-color: rgba(0,0,0,0);
    cursor: pointer;
    margin-left: 2px;
    padding: 2px;
}

.headerButtonContainer:hover {
    background-color: rgba(80,80,80,1);
}

.headerButtonContainerActive {
    background-color: rgba(80,80,80,1);
}

.headerButton {
    width: 28px;
    height: 28px;
    cursor: pointer;
    opacity: 0.6;
    transition: all 0.1s ease-in-out;
}

.headerButton:hover {
    opacity: 1;
    transform: scale(1.15);
}

.headerButtonActive {
    opacity: 1;
}

.header > ul {
    list-style: none;
    display: flex;
    position: fixed;
    top: 0;
    right: 40px;
    height: 40px;
}

.header > ul > li {
    font-size: 16px;
    margin-left: 24px;
    height: 40px;
}

.navLink, .header > ul > li > a {
    color: rgb(220, 220, 220);
    font-weight: 200;
    text-decoration: none;
    transition: all 0.1s ease-in-out;
    display: block;
    height: 40px;
    cursor: pointer;
}

.navLink:hover, .header > ul > li > a:hover {
    color: rgb(230,155,30);
}

.activeRoute {
    border-top: solid 4px rgb(230,155,30);
    line-height: 32px;
}

.profileContainer {
    left: 12px;
    position: absolute;
    background-color: white;
    opacity: 1.0;
    border: 1px solid rgb(180,180,180);
    border-radius: 4px;
    width: 250px;
    height: 200px;
    top: 43px;
    z-index: 100;
    overflow: hidden;
}

.profileUserName {
    top: 16px;
    left: 10px;
    position: absolute;
    color: rgb(80,80,80);
    font-size: 16px;
    line-height: 20px;
    height: 20px;
}

.profileCase {
    top: 45px;
    left: 10px;
    position: absolute;
    color: rgba(80,80,80, 0.7);
    font-size: 14px;
    line-height: 20px;
    height: 20px;
}
.profileStudy {
    top: 65px;
    left: 10px;
    position: absolute;
    color: rgba(80,80,80, 0.7);
    font-size: 14px;
    line-height: 20px;
    height: 20px;
}
.profileDOB {
    top: 85px;
    left: 10px;
    position: absolute;
    color: rgba(80,80,80, 0.7);
    font-size: 14px;
    line-height: 20px;
    height: 20px;
}
.profileDOE {
    top: 105px;
    left: 10px;
    position: absolute;
    color: rgba(80,80,80, 0.7);
    font-size: 14px;
    line-height: 20px;
    height: 20px;
}
.profilePhys {
    top: 125px;
    left: 10px;
    position: absolute;
    color: rgba(80,80,80, 0.7);
    font-size: 14px;
    line-height: 20px;
    height: 20px;
}


.profileButton {
    position: absolute;
    left: 4px;
    right: 4px;
    height: 30px;
    border-radius: 4px;
    line-height: 30px;
    text-align: center;
    color: white;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.logoutButton {
    top: 160px;
    background-color: rgb(230,155,30);
}


.viewerToolsContainer {
    height: 32px;
    line-height: 32px;
    padding: 0;

    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    color: rgb(220,220,220);

}

.activeRequestIndicator {

    margin-right: 20px;
    float: left;
    color: rgba(255, 255, 255, 0.7);


}



.customTypeButton {
    position: absolute;

    left: 10px;
    right: 10px;
    bottom: 10px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    text-align: center;
    color: white;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    background-color: rgb(230,155,30);
    opacity: 0.6;

}

.customTypeButton:hover {
    opacity: 1.0;
}


/* Cloud sync indicator */

.cloudSyncIndicator {
    margin-left: 10px;
    float: left;
    opacity: 0.7;
    font-size: 14px;
}

.cloudSynced {
    opacity: 0.4
}


/* Help Bar */

.helpBar {

    position: fixed;
    right: 0px;
    top: 34px;
    width: 400px;
    height: 100%;
    background-color: rgba(0,0,0,0.9);
    z-index: 100;
    transition: ease-in-out 0.2s all;
    color: rgb(250,250,250);
    user-select: none;

    padding-bottom: 100px;
}

/* #region Hotkeys */

.hotkeyContainer {
    position: relative;
    height: calc(100% - 34px);
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;

    padding-left: 20px;
}

.hotkeyContainer_collapsed {
    position: relative;
    margin-top: 20px;
    width: 80%;
    left: 10%;
    border: 2px solid rgb(220, 220, 220);
}

/* Scroll Bar */
.hotkeyContainer::-webkit-scrollbar {
    width: 5px;
    height: 0px;    
}
.hotkeyContainer::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 1);
    border-radius: 15px;
}
.hotkeyContainer::-webkit-scrollbar-thumb {
    background: rgba(150, 150, 150, 1);
    border-radius: 15px;
}
.hotkeyContainer::-webkit-scrollbar-thumb:hover {
    background: rgb(85, 85, 85);
}

.hotkeyHeader {
    position: relative;
    text-align: left;
    margin-top: 15px;
    line-height: 30px;
    opacity: 0.75;
    transition: ease-in-out 0.1s all;
}

.hotkeyHeader:hover {

    opacity: 0.9;
    color: rgb(0,220,220);

}

.hotkeySubHeader {
    margin-bottom: 20px;
    opacity: 0.85;
    font-weight: normal;
}

.hotkeyRow {
    position: relative;
    left: 20px;
}

.hotkeyDescription {
    display: inline-block;
    height: 40px;
    left: 0;
    opacity: 0.7;

}

.hotkeyDescription > img {
    position: relative;
    top: 11px;
}

.hotkeyPattern {
    /* position: inlin; */
    display: inline-block;
    /* width: 100%; */
    height: 40px;
    left: 100px;
    margin-left: 10px;

}

kbd {
    /* background-color: rgb(60, 60, 180); */
    padding: 4px !important;
    border-radius: 4px;
    border: 1px solid rgba(200,200,200, 0.4);
}

.helpBar h1, h4 {
    padding: 0;
    margin: 0;
}

/* #endregion Hotkeys */


/* Cancel Button */

.cancelButton {

    position: relative;
    top: 4px;

    margin-right: 15px;
    float: left;
    color: rgb(240,240,240);
    background-color: rgb(160,0,0);
    border: 1px solid rgb(200,0,0);
    padding : 0px 6px;

    height: 22px;
    border-radius: 4px;
    line-height: 22px;
    text-align: center;
    color: white;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

}

.cancelButton:hover {
    color: white;
    background-color: rgb(200,0,0);
    border: 1px solid rgb(240,0,0);
}