/*
Developed by Aptus Engineering, Inc. <https://aptusai.com>
See LICENSE.md file in project root directory
*/


* {
    font-family: 'Barlow', sans-serif;
}

html, body {
    margin: 0;
    padding: 0;
}

.loadingIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -60px;
    margin-top: -60px;
}


.loadingContainer {
    left: 0px;
    right: 0px;
    min-width: 918px;
    top: 30px;
    height: calc(100% - 30px);
    width: 100%;
    position: fixed;
    z-index: 100;
    background-color: rgba(0,0,0,0.3);
    /* -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
}