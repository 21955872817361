/*
Developed by Aptus Engineering, Inc. <https://aptusai.com>
See LICENSE.md file in project root directory
*/

/* Locations Dropdowns */
.locationDropContainer {
    margin: 4px;
    border: 1px solid rgb(50,50,50);
}

.locationDropContainerOpened {
    border: 1px solid rgb(80,80,80);
    background-color: rgb(45,45,45);
}

.locationDropHeader {
    position: relative;
    height: 32px;
    width: 100%;
    margin: auto;
    color: rgb(0,220,220);
    cursor: pointer;
    font-weight: 500;
    transition: 0.15s ease-in-out all;
}

.locationDropHeader:hover {
    background-color: rgb(50,50,50);
}

.locationHeaderTitle {
    margin-left: 12px;
    line-height: 30px;
}


/* Location Visibilities */
.objectButton {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 7px;
    top: 7px;
    cursor: pointer;
    opacity: 1;
    transition: 0.15s ease-in-out all;
}

.objectButton:hover {
    transform: scale(1.3);
}

/* Object Dropdowns */
.objectDropContainer {
    position: relative;
    margin: 4px;
    border: 1px solid rgb(80,80,80);
    color: rgb(160,160,160);
}

.objectDropHeader {
    position: relative;
    width: calc(100% - 20px);
    padding-left: 20px;
    height: auto;
    min-height: 30px;
}

.objectDropHeader:hover {
    background-color: rgb(60,60,60);
}

.objectDropTitle {
    line-height: 30px;
    margin-left: 5px;
    cursor: pointer;
}

.objectDropdown {
    border-top: 1px solid rgb(50,50,50);
    padding: 10px;
    padding-left: 44px;
}

.objectDeleteButton {
    width: 20px;
    position: absolute;
    top: 5px;
    right: 5px;
    opacity: 0.4;
    transition: ease-in-out 0.1s all;
}

.objectDeleteButton:hover {
    opacity: 1.0;
}

.objectCreate {
    width: 28px;
    height: 28px;
    float: left;
    margin-top: 2px;
    margin-left: -5px;
}