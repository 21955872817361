/*
Developed by Aptus Engineering, Inc. <https://aptusai.com>
See LICENSE.md file in project root directory
*/

.labelContainer {
    
    position: absolute;

    left: 25px;
    bottom: 25px;

    width: auto;
    /* height: 40px; */

    /* border-radius: 5px; */

    /* background-color: rgba(200, 200, 200, 0.5); */
    color: white;
}


.labelTextArea {

    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 0;

    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 14px;
    line-height: 19px;
    font-family: 'Barlow';

    border-radius: 0px;
    border: none;
    
    /* width: calc(100% - 30px); */
    width: auto;
    /* max-width: 150px; */
    overflow: hidden;

    resize: none;

    transition: ease-in-out 0.1s background-color;

}

.labelTextArea:focus {
    outline: none !important;
}

.labelTextAreaDelete {
    background-color: rgba(255, 100, 100, 0.4);
    cursor: pointer;
}

.labelTextAreaHovered{
    background-color: rgba(73, 229, 25, 0.4);
    cursor: pointer;
}

.labelIndicator {
    position: absolute;
    bottom: -20px;
    left: 0;
    font-size: 12px; 
    color: rgba(255,255,255, 0.6);

}