/*
Developed by Aptus Engineering, Inc. <https://aptusai.com>
See LICENSE.md file in project root directory
*/

.annotationLocDropContainer { 
    width: calc(100% - 4px);
    border: 1px solid red;
}


.annotationLocDropdown {
    position: relative;
    width: calc(100% - 8px);
    margin-bottom: 5px;
    margin-top: 5px;
}

.annotationIcon {
    float: left;
    width: 24px;
    height: 24px;
    margin-top: 4px;
    margin-right: 6px;
}

.annotationDelete {
    position: absolute;
    right: 6px;
    top: 7px;
    width: 16px;
    height: 16px;
    transition: ease-in-out 0.1s all;
    cursor: pointer;
}